import { useRef, useState } from 'react';
import {
    Button, Checkbox, Col, Form, Input, Row, Space,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { RuleObject } from 'antd/lib/form';
import { StoreValue } from 'antd/lib/form/interface';
import AuthorizationContent from '../components/authorization-content';
import { getRequiredFieldMessage } from '../helpers/form';
import { useFetch } from '../hooks/useFetch';
import { useApp } from '../hooks/useApp';
import { useForm } from '../hooks/useForm';
import PhoneInput from '../components/phone-input';
import { getLanguageForApi } from '../helpers/language';
import FormItemWrapper from '../containers/form-item-wrapper';
// import FormItemWrapper from '../containers/form-item-wrapper';

interface ILoginResponse {
    token?: string;
    isNew?: boolean;
}

function LoginPage() {
    const app = useApp();
    const [byPhone, setByPhone] = useState(true);
    const [nextStep, setNextStep] = useState(false);
    const fetch = useFetch();
    const { t, i18n } = useTranslation();
    const [form] = useForm();
    const isNew = useRef(false);

    const checkBoxValidator = (_: RuleObject, value: StoreValue, callback: (error?: string) => void) => {
        if (!value) {
            return callback(getRequiredFieldMessage());
        }
        return callback();
    };

    const onFinish = async (data: any) => {
        const result = await fetch.request<ILoginResponse>({
            url: nextStep ? '/login/confirm' : '/login',
            data: {
                ...data,
                language: getLanguageForApi(i18n.language),
            },
            method: 'post',
        });
        if (result.success) {
            if (result.data instanceof Object) {
                if (typeof result.data.isNew === 'boolean') {
                    isNew.current = result.data.isNew;
                }
                if (typeof result.data.token === 'string') {
                    app.authSucceed(result.data.token);
                }
            }
            setNextStep(true);
        }
    };

    // const onRegisterClick = () => navigate('/register');

    // const onRestoreClick = () => navigate('/forgot-password');

    return (
        <AuthorizationContent title={t('authorization')}>
            <Space className="w-100" direction="vertical">
                <Button hidden type="link" onClick={() => setByPhone((value) => !value)}>
                    {byPhone ? t('authorizationByEmail') : t('authorizationByPhone')}
                </Button>
                <Form form={form} layout="vertical" onFinish={onFinish}>
                    <Row gutter={[30, 30]}>
                        <Col xs={24}>
                            <Form.Item
                                label={t('phone')}
                                name="phone"
                                rules={[{
                                    required: true,
                                    message: getRequiredFieldMessage(t('phone')),
                                }]}
                            >
                                <PhoneInput />
                            </Form.Item>
                        </Col>
                        {nextStep && (
                            <Col xs={24}>
                                <Form.Item
                                    label={t('confirmationCode')}
                                    name="code"
                                    rules={[{
                                        required: true,
                                        message: getRequiredFieldMessage(t('confirmationCode')),
                                    }]}
                                >
                                    <FormItemWrapper component={Input} />
                                </Form.Item>
                            </Col>
                        )}
                        <Col xs={24}>
                            <Form.Item
                                className="checkbox-form-item"
                                rules={[{ validator: checkBoxValidator }]}
                                name="personal-data"
                                valuePropName="checked"
                            >
                                <Space>
                                    <Checkbox />
                                    <Button
                                        className="height-auto"
                                        type="link"
                                        target="_blank"
                                        href="https://telega.pro/personal-data"
                                    >
                                        {t('acceptPersonalData')}
                                    </Button>
                                </Space>
                            </Form.Item>
                        </Col>
                        {/* {!byPhone && (
                            <Col xs={24}>
                                <Form.Item
                                    label={t('email')}
                                    name="email"
                                    rules={[
                                        { required: true, message: getRequiredFieldMessage(t('email')) },
                                        { type: 'email', message: getEnteredIncorrectlyFieldMessage(t('email')) },
                                    ]}
                                >
                                    <Input type="email" />
                                </Form.Item>
                            </Col>
                        )} */}
                        {/* <Col xs={24}>
                            <Form.Item
                                label={t('password')}
                                name="password"
                                rules={[{
                                    required: true,
                                    message: getRequiredFieldMessage(t('password')),
                                }]}
                            >
                                <FormItemWrapper component={Input.Password} />
                            </Form.Item>
                        </Col> */}
                        <Col xs={24}>
                            <Space className="w-100" direction="vertical" size="large">
                                <Button
                                    className="w-100 ant-btn-dark"
                                    type="default"
                                    htmlType="submit"
                                    size="large"
                                    loading={fetch.loading}
                                >
                                    {t('login')}
                                </Button>
                                {/* <Space className="w-100 row-space-between justify-content-end" direction="horizontal" wrap>
                                    <Button className="w-100 pl-0" type="link" onClick={onRegisterClick}>
                                        {t('registration')}
                                    </Button>
                                    <Button className="w-100 pr-0" type="link" onClick={onRestoreClick}>
                                        {t('passwordForgot')}
                                    </Button>
                                </Space> */}
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </Space>
        </AuthorizationContent>
    );
}

export default LoginPage;
