/* eslint-disable no-undef */
/* eslint-disable react/jsx-props-no-spreading */
import {
    Col, Form, FormInstance, FormProps, Grid, Input, Row,
    TreeSelect,
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
    ChangeEventHandler, forwardRef, useEffect, useRef, useState,
} from 'react';
import { getRequiredFieldMessage, onInputWheel } from '../helpers/form';
import { usePackageUnits } from '../hooks/usePackageUnits';
import { useOrderItemTik } from '../hooks/useOrderItemTik';

const { useBreakpoint } = Grid;

function PurchaseItemForm(props: FormProps, ref: any) {
    const { form, initialValues } = props;

    const { lg } = useBreakpoint();
    const { t } = useTranslation();
    const packageUnits = usePackageUnits(true);
    const [ruName, setRuName] = useState('');
    const tik = useOrderItemTik(ruName, typeof ruName === 'string' && ruName.length > 0);
    const ruNameChangeTimeout = useRef<NodeJS.Timeout>();

    const onNameInputChange: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
        form?.setFieldValue('tncode', undefined);
        clearTimeout(ruNameChangeTimeout.current);
        ruNameChangeTimeout.current = setTimeout(() => {
            setRuName(target.value);
        }, 500);
    };

    useEffect(() => {
        setRuName(initialValues?.ru_name);
    }, [initialValues]);

    return (
        <Form ref={ref} form={form} {...props} layout="vertical">
            <Row gutter={[30, 30]}>
                <Col xs={24} md={12}>
                    <Form.Item
                        name="name"
                        label={t('goodName')}
                        rules={[{ required: true, message: getRequiredFieldMessage(t('goodName')) }]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item
                        name="ru_name"
                        label={t('orderPackageRuName')}
                        rules={[{ required: true, message: getRequiredFieldMessage(t('orderPackageRuName')) }]}
                    >
                        <Input onChange={onNameInputChange} />
                    </Form.Item>
                </Col>
                <Col xs={24}>
                    <Form.Item
                        name="tncode"
                        label={t('orderPackageCode')}
                        rules={[{ required: tik.list.length > 0, message: getRequiredFieldMessage(t('orderPackageCode')) }]}
                    >
                        <TreeSelect
                            treeData={tik.list}
                            loading={tik.loading}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item
                        name="count"
                        label={t('orderPackageItemsCount')}
                        rules={[{ required: true, message: getRequiredFieldMessage(t('orderPackageItemsCount')) }]}
                    >
                        <Input type="number" onWheel={onInputWheel} min={1} />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item
                        name="price"
                        label={lg ? `${t('orderPackageCost')} (${t('perItem')})` : t('orderPackageCost')}
                        rules={[{ required: true, message: getRequiredFieldMessage(t('orderPackageCost')) }]}
                    >
                        <Input type="number" onWheel={onInputWheel} min={0} step={0.01} />
                    </Form.Item>
                </Col>
                <Col xs={12}>
                    <Form.Item
                        label={lg ? `${t('orderPackageWeight')} (${t('perItem')})` : t('orderPackageWeight')}
                        name="weight"
                        rules={[{ required: true, message: getRequiredFieldMessage(t('orderPackageWeight')) }]}
                    >
                        <Input
                            onKeyPress={(event) => {
                                if (!/[0-9,.]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            suffix={packageUnits.data?.weight[0].label}
                            min={0}
                            onWheel={onInputWheel}
                        />
                    </Form.Item>
                    <Form.Item hidden name="weight_unit" />
                </Col>
                <Col xs={12}>
                    <Form.Item
                        label={lg ? `${t('orderPackageWidth')} (${t('perItem')})` : t('orderPackageWidth')}
                        name="width"
                        rules={[{ required: true, message: getRequiredFieldMessage(t('orderPackageWidth')) }]}
                    >
                        <Input suffix={packageUnits.data?.linear[0].label} type="number" min={0} onWheel={onInputWheel} />
                    </Form.Item>
                    <Form.Item hidden name="width_unit" />
                </Col>
                <Col xs={12}>
                    <Form.Item
                        label={lg ? `${t('orderPackageLength')} (${t('perItem')})` : t('orderPackageLength')}
                        name="length"
                        rules={[{ required: true, message: getRequiredFieldMessage(t('orderPackageLength')) }]}
                    >
                        <Input suffix={packageUnits.data?.linear[0].label} type="number" min={0} onWheel={onInputWheel} />
                    </Form.Item>
                    <Form.Item hidden name="length_unit" />
                </Col>
                <Col xs={12}>
                    <Form.Item
                        label={lg ? `${t('orderPackageHeight')} (${t('perItem')})` : t('orderPackageHeight')}
                        name="height"
                        rules={[{ required: true, message: getRequiredFieldMessage(t('orderPackageHeight')) }]}
                    >
                        <Input suffix={packageUnits.data?.linear[0].label} type="number" min={0} onWheel={onInputWheel} />
                    </Form.Item>
                    <Form.Item hidden name="height_unit" />
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item
                        name="url"
                        label={t('orderPackageItemUrl')}
                        rules={[{ required: true, message: getRequiredFieldMessage(t('orderPackageItemUrl')) }]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Form.Item name="currency" hidden />
            </Row>
        </Form>
    );
}

export default forwardRef<FormInstance, FormProps>(PurchaseItemForm);
