import {
    Button, Col, DatePicker, Form, Input, Row, Select, Space, Spin, Typography,
} from 'antd';
import { CountryCode, isValidPhoneNumber } from 'libphonenumber-js';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { DefaultOptionType } from 'antd/lib/select';
import { useEffect, useState } from 'react';
import { getRequiredFieldMessage } from '../../helpers/form';
import { useCountry } from '../../hooks/useCountry';
import { useFetch } from '../../hooks/useFetch';
import { useForm } from '../../hooks/useForm';
import { useMemberAutocomplete } from '../../hooks/useMemberAutocomplete';
import { useOrder } from '../../hooks/useOrder';
import { IOrder, IOrderMember } from '../../types/order';
import PhoneInput from '../phone-input';
import { IMakeOrderStepsProps } from './first';
import { useOrderForm } from '../../hooks/useOrderForm';
import { useFormWatchMode } from '../../hooks/useFormWatchMode';

interface IMakeOrderMemberStepProps {
    type: 'SENDER' | 'RECIPIENT';
}

function MakeOrderMemberStep({
    onBackPress, goNextStep, type, canMoveBetweenSteps,
}: IMakeOrderMemberStepProps & IMakeOrderStepsProps) {
    const fetchTransferOptions = useFetch();
    const saveFetch = useFetch();
    const order = useOrder();
    const { i18n, t } = useTranslation();
    const [form] = useForm();
    const countryISO = type === 'RECIPIENT' ? order.data?.to : order.data?.from;
    const countryData = useCountry(countryISO);
    const autocomplete = useMemberAutocomplete(form, type);
    const { availableFormItems } = useOrderForm(false);
    const { getFieldMandatory, selectOpen, watchMode } = useFormWatchMode();
    const [transferOptions, setTransferOptions] = useState<DefaultOptionType[]>([]);
    const [deliveryToCompanyType, setDeliveryToCompanyType] = useState<string>();

    const labelByType = type === 'RECIPIENT' ? 'Recipient' : 'Sender';
    const target = type === 'RECIPIENT' ? order.data?.recipient : order.data?.sender;
    const parcelshopsLink = transferOptions.find((el) => el.value === deliveryToCompanyType)?.parcelshop_link;

    const getOptions = async () => {
        const result = await fetchTransferOptions.request<DefaultOptionType[]>({
            url: `/orders/transfer-options?orderId=${order.data?.id}`,
        });
        if (result.success && Array.isArray(result.data)) {
            setTransferOptions(result.data);
        }
    };

    useEffect(() => {
        if (typeof order.data?.id === 'number') {
            getOptions();
        }
    }, [i18n.language, order.data?.id]);

    useEffect(() => {
        if (typeof order.data?.delivery_to_company_type === 'string') {
            setDeliveryToCompanyType(order.data?.delivery_to_company_type);
        }
    }, [order.data?.delivery_to_company_type]);

    const onFinish = async (values: Record<string, any>) => {
        await saveFetch.request<IOrder>({
            url: `/orders/${order.data?.id}/update`,
            data: values,
            method: 'post',
        });
        const result = await saveFetch.request<IOrderMember>({
            url: typeof target?.id !== 'undefined' ? `/order-members/${target.id}/update` : '/order-members/create',
            data: values,
            method: 'post',
        });
        if (result.success) {
            await order.update(result.data?.order_id);
            if (canMoveBetweenSteps) {
                goNextStep();
            }
        }
    };

    const initialValues = {
        type,
        order_id: order.data?.id,
        delivery_to_company_type: order.data?.delivery_to_company_type,
        pickup_date: order.data?.pickup_date ? moment(order.data.pickup_date) : undefined,
        ...target,
        passport_date: typeof target?.passport_date === 'string' ? moment(target.passport_date) : undefined,
    };

    return (
        <fieldset disabled={watchMode}>
            <Spin spinning={autocomplete.selection.loading}>
                <Form form={form} initialValues={initialValues} layout="vertical" onFinish={onFinish}>
                    <Row gutter={[30, 30]}>
                        <Col xs={24}>
                            {autocomplete.list.data.length > 0 && !watchMode && (
                                <Row gutter={[30, 30]}>
                                    <Col xs={24} md={12} xl={8}>
                                        <Form.Item label={t('autoFill')}>
                                            <Select
                                                options={autocomplete.list.data}
                                                onSelect={autocomplete.selection.onSelect}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            )}
                        </Col>
                        {type === 'SENDER' && (
                            <Col xs={24}>
                                <Row gutter={[30, 30]}>
                                    {order.data?.type === 'common' && (
                                        <Col xs={24} md={12}>
                                            <Space className="w-100" direction="vertical">
                                                <Form.Item
                                                    label={t('orderDeliveryToCompanyTypeTitle')}
                                                    name="delivery_to_company_type"
                                                    rules={[{ required: getFieldMandatory(true), message: getRequiredFieldMessage(t('orderDeliveryToCompanyTypeTitle')) }]}
                                                >
                                                    <Select
                                                        open={selectOpen}
                                                        loading={fetchTransferOptions.loading}
                                                        options={transferOptions}
                                                        onSelect={setDeliveryToCompanyType}
                                                    />
                                                </Form.Item>
                                                {typeof parcelshopsLink === 'string' && order.data?.from !== 'CN' && (
                                                    <Button href={parcelshopsLink} type="link" target="_blank">
                                                        {t('glsParcelShops')}
                                                    </Button>
                                                )}
                                            </Space>
                                        </Col>
                                    )}
                                    {deliveryToCompanyType === 'BY_COURIER' && (
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                className="ant-form-item-with-extra ant-form-item-label-always-top"
                                                extra={<b>{t('courierPickUpInfo')}</b>}
                                                label={t('orderPackagePickupDate')}
                                                name="pickup_date"
                                                rules={[{ required: getFieldMandatory(true), message: getRequiredFieldMessage(t('orderPackagePickupDate')) }]}
                                            >
                                                <DatePicker
                                                    open={selectOpen}
                                                    className="w-100"
                                                    format="DD.MM.YYYY"
                                                    disabledDate={(curr) => curr < moment()}
                                                    showNow={false}
                                                    allowClear={!watchMode}
                                                />
                                            </Form.Item>
                                        </Col>
                                    )}
                                </Row>
                            </Col>
                        )}
                        <Col xs={24} md={12} xl={8}>
                            <Form.Item
                                extra={t('orderMemberFullNameInfo')}
                                label={t(`orderMember${labelByType}Name`)}
                                name="name"
                                rules={[
                                    { required: getFieldMandatory(true), message: getRequiredFieldMessage(t(`orderMember${labelByType}Name`)) },
                                    { pattern: new RegExp('^[^А-Яа-я]*$'), message: t('inputOfLatinLetters') as string },
                                ]}
                            >
                                <Input placeholder="Ivan" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12} xl={8}>
                            <Form.Item
                                extra={t('orderMemberFullNameInfo')}
                                label={t(`orderMember${labelByType}Surname`)}
                                name="surname"
                                rules={[
                                    { required: getFieldMandatory(true), message: getRequiredFieldMessage(t(`orderMember${labelByType}Surname`)) },
                                    { pattern: new RegExp('^[^А-Яа-я]*$'), message: t('inputOfLatinLetters') as string },
                                ]}
                            >
                                <Input placeholder="Ivanov" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12} xl={8}>
                            <Form.Item
                                label={t(`orderMember${labelByType}Middlename`)}
                                name="middle_name"
                                rules={[{ pattern: new RegExp('^[^А-Яа-я]*$'), message: t('inputOfLatinLetters') as string }]}
                            >
                                <Input placeholder="Ivanovich" />
                            </Form.Item>
                        </Col>
                        {availableFormItems.representative?.memberTypes?.includes(type) && (
                            <Col xs={24}>
                                <Row>
                                    <Col xs={24} md={12} xl={8}>
                                        <Form.Item
                                            label={t(`orderMember${labelByType}Representative`)}
                                            name="representative"
                                            rules={[{ pattern: new RegExp('^[^А-Яа-я]*$'), message: t('inputOfLatinLetters') as string }]}
                                        >
                                            <Input placeholder={t('representativePlaceholder') as string} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        )}
                        {availableFormItems.phone?.memberTypes?.includes(type) && (
                            <Col xs={24} md={12} xl={8}>
                                <Form.Item
                                    label={t(`orderMember${labelByType}Phone`)}
                                    name="phone"
                                    rules={[
                                        { required: getFieldMandatory(true), message: getRequiredFieldMessage(t(`orderMember${labelByType}Phone`)) },
                                        { pattern: new RegExp('^[^А-Яа-я]*$'), message: t('inputOfLatinLetters') as string },
                                        {
                                            validator: async (_: any, value: string) => {
                                                if (typeof countryISO === 'string' && !isValidPhoneNumber(value, countryISO as CountryCode)) {
                                                    throw new Error(t('incorrectPhoneNumber') as string);
                                                }
                                            },
                                        },
                                    ]}
                                >
                                    <PhoneInput
                                        defaultCountry={type === 'SENDER' ? order.data?.from : undefined}
                                        country={type === 'RECIPIENT' ? order.data?.to : undefined}
                                    />
                                </Form.Item>
                            </Col>
                        )}
                        {availableFormItems.email?.memberTypes?.includes(type) && (
                            <Col xs={24} md={12} xl={8}>
                                <Form.Item
                                    label={t(`orderMember${labelByType}Email`)}
                                    name="email"
                                    rules={[
                                        { required: getFieldMandatory(true), message: getRequiredFieldMessage(t(`orderMember${labelByType}Email`)) },
                                        { pattern: new RegExp('^[^А-Яа-я]*$'), message: t('inputOfLatinLetters') as string },
                                    ]}
                                >
                                    <Input placeholder="ivan@example.com" />
                                </Form.Item>
                            </Col>
                        )}
                        {availableFormItems.region?.memberTypes?.includes(type) && (
                            <Col xs={24} md={12} xl={8}>
                                <Form.Item
                                    extra={t('orderMemberRegionInfo')}
                                    label={t(`orderMember${labelByType}Region`)}
                                    name="region"
                                    rules={[
                                        { required: getFieldMandatory(true), message: getRequiredFieldMessage(t(`orderMember${labelByType}Region`)) },
                                        { pattern: new RegExp('^[^А-Яа-я]*$'), message: t('inputOfLatinLetters') as string },
                                    ]}
                                >
                                    <Input placeholder="Moscow" />
                                </Form.Item>
                            </Col>
                        )}
                        {availableFormItems.city?.memberTypes?.includes(type) && (
                            <Col xs={24} md={12} xl={8}>
                                <Form.Item
                                    extra={t('orderMemberCityInfo')}
                                    label={t(`orderMember${labelByType}City`)}
                                    name="city"
                                    rules={[
                                        { required: getFieldMandatory(true), message: getRequiredFieldMessage(t(`orderMember${labelByType}City`)) },
                                        { pattern: new RegExp('^[^А-Яа-я]*$'), message: t('inputOfLatinLetters') as string },
                                    ]}
                                >
                                    <Input placeholder="Moscow" />
                                </Form.Item>
                            </Col>
                        )}
                        {availableFormItems.street?.memberTypes?.includes(type) && (
                            <Col xs={24} md={12} xl={8}>
                                <Form.Item
                                    label={t('district')}
                                    name="district"
                                    rules={[
                                        { pattern: new RegExp('^[^А-Яа-я]*$'), message: t('inputOfLatinLetters') as string },
                                    ]}
                                >
                                    <Input placeholder="Center" />
                                </Form.Item>
                            </Col>
                        )}
                        {availableFormItems.street?.memberTypes?.includes(type) && (
                            <Col xs={24} md={12} xl={8}>
                                <Form.Item
                                    extra={t('orderMemberStreetInfo')}
                                    label={t(`orderMember${labelByType}Street`)}
                                    name="street"
                                    rules={[
                                        { required: getFieldMandatory(true), message: getRequiredFieldMessage(t(`orderMember${labelByType}Street`)) },
                                        { pattern: new RegExp('^[^А-Яа-я]*$'), message: t('inputOfLatinLetters') as string },
                                    ]}
                                >
                                    <Input placeholder="Lenina" />
                                </Form.Item>
                            </Col>
                        )}
                        {availableFormItems.postal_code?.memberTypes?.includes(type) && (
                            <Col xs={24} md={12} xl={8}>
                                <Form.Item
                                    label={t(`orderMember${labelByType}PostalCode`)}
                                    name="postal_code"
                                    rules={[{
                                        required: getFieldMandatory(true),
                                        pattern: typeof countryData.data?.postal_code_regex === 'string'
                                            ? new RegExp(countryData.data.postal_code_regex) : undefined,
                                        message: getRequiredFieldMessage(t(`orderMember${labelByType}PostalCode`)),
                                    }]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        )}
                        {availableFormItems.house?.memberTypes?.includes(type) && (
                            <Col xs={24} md={12} xl={8}>
                                <Form.Item
                                    label={t(`orderMember${labelByType}House`)}
                                    name="house"
                                    rules={[
                                        { required: getFieldMandatory(true), message: getRequiredFieldMessage(t(`orderMember${labelByType}House`)) },
                                        { pattern: new RegExp('^[^А-Яа-я]*$'), message: t('inputOfLatinLetters') as string },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        )}
                        {availableFormItems.building?.memberTypes?.includes(type) && (
                            <Col xs={24} md={12} xl={8}>
                                <Form.Item
                                    label={t(`orderMember${labelByType}Building`)}
                                    name="building"
                                    rules={[{ pattern: new RegExp('^[^А-Яа-я]*$'), message: t('inputOfLatinLetters') as string }]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        )}
                        {availableFormItems.apartment?.memberTypes?.includes(type) && (
                            <Col xs={24} md={12} xl={8}>
                                <Form.Item
                                    label={t(`orderMember${labelByType}Apartment`)}
                                    name="apartment"
                                    rules={[{ pattern: new RegExp('^[^А-Яа-я]*$'), message: t('inputOfLatinLetters') as string }]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        )}
                        {order.data?.from === 'CN' && (
                            <Col xs={24} md={12} xl={8}>
                                <Form.Item
                                    label={t('inn')}
                                    name="inn"
                                    rules={[{ pattern: new RegExp('^[^А-Яа-я]*$'), message: t('inputOfLatinLetters') as string }]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        )}
                        {availableFormItems.passport?.memberTypes?.includes(type) && order.data?.from === 'CN' && (
                            <>
                                <Col xs={24}>
                                    <Typography.Title level={5}>{t('orderMemberPassport')}</Typography.Title>
                                </Col>
                                <Col xs={24} md={12} xl={8}>
                                    <Form.Item
                                        label={t('orderMemberPassportNumber')}
                                        name="passport_number"
                                        rules={[{
                                            required: getFieldMandatory(true),
                                            message: getRequiredFieldMessage(t('orderMemberPassportNumber')),
                                        }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} xl={8}>
                                    <Form.Item
                                        label={t('orderMemberPassportSerialNumber')}
                                        name="passport_serial_number"
                                        rules={[{
                                            required: getFieldMandatory(true),
                                            message: getRequiredFieldMessage(t('orderMemberPassportSerialNumber')),
                                        }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} xl={8}>
                                    <Form.Item
                                        label={t('orderMemberPassportIssuingAuthority')}
                                        name="passport_issuing_authority"
                                        rules={[{
                                            required: getFieldMandatory(true),
                                            message: getRequiredFieldMessage(t('orderMemberPassportIssuingAuthority')),
                                        }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} xl={8}>
                                    <Form.Item
                                        label={t('orderMemberPassportDate')}
                                        name="passport_date"
                                        rules={[{
                                            required: getFieldMandatory(true),
                                            message: getRequiredFieldMessage(t('orderMemberPassportDate')),
                                        }]}
                                    >
                                        <DatePicker className="w-100" format="DD.MM.YYYY" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} xl={8}>
                                    <Form.Item
                                        label={t('orderMemberPassportDivisionCode')}
                                        name="passport_division_code"
                                        rules={[{
                                            required: getFieldMandatory(true),
                                            message: getRequiredFieldMessage(t('orderMemberPassportDivisionCode')),
                                        }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </>
                        )}
                        <Form.Item hidden name="type" />
                        <Form.Item hidden name="order_id" />
                        <Col className="text-center" xs={24}>
                            <Space className="form-action-buttons" direction="horizontal" hidden={watchMode} size="large">
                                {canMoveBetweenSteps && (
                                    <Button type="link" onClick={onBackPress}>
                                        {t('back')}
                                    </Button>
                                )}
                                <Button type="primary" htmlType="submit" loading={saveFetch.loading || order.dataLoading}>
                                    {canMoveBetweenSteps ? t('next') : t('update')}
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </fieldset>
    );
}

export default MakeOrderMemberStep;
