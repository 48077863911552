import {
    Divider, Menu as AMenu, Row, Button, Col, Space,
} from 'antd';
import {
    CalculatorFilled,
    CustomerServiceFilled,
    DownOutlined, InfoCircleFilled, QuestionCircleFilled, RightOutlined,
} from '@ant-design/icons';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import LogoPNG from '../assets/images/logo-long.png';
import LogoNoTextPNG from '../assets/images/logo-no-text.png';

import '../styles/menu.less';
import { LanguagesDropDown } from './header';
import UserIcon from '../assets/icons/user';
import MapMarkerIcon from '../assets/icons/marker';
import BoxIcon from '../assets/icons/box';
import { useUser } from '../hooks/useUser';

interface IMenuProps {
    collapsed?: boolean;
    drawer?: boolean;
}

const links: Record<string, {
    faq: string;
    guide: string;
    contacts: string;
    logo: string;
}> = {
    'de-DE': {
        contacts: 'contacts-de',
        faq: 'faq-de',
        guide: 'guide-de',
        logo: 'https://telega.pro/de',
    },
    'en-EN': {
        contacts: 'contacts-en',
        faq: 'faq-en',
        guide: 'guide-en',
        logo: 'https://telega.pro/en',
    },
    'ru-RU': {
        contacts: 'contacts',
        faq: 'faq',
        guide: 'guide',
        logo: 'https://telega.pro',
    },
};

function Menu({ collapsed, drawer }: IMenuProps) {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { t, i18n } = useTranslation();
    const { info } = useUser();
    // const orderTypes = useOrderTypes();
    // const [orderTypesOpen, setOrderTypesOpen] = useState(false);
    const [profileOpen, setProfileOpen] = useState(false);

    const onItemClick = (key: string) => {
        navigate(`/${key}`);
    };

    const activeKey = pathname.replace('/', '') || 'profile';

    // const getOrderTypesChildren = (): ItemType[]|undefined => (orderTypes.data.length > 0 ? orderTypes.data.map((ot) => ({
    //     key: `make-order/${ot.value}`,
    //     label: ot.label,
    // })) : undefined);

    // const menuItems: (ItemType & { href?: string; })[] = [
    //     {
    //         label: t('profile'),
    //         key: 'profile',
    //         icon: <UserIcon />,
    //         children: [{
    //             key: 'orders',
    //             label: t('orders'),
    //         }, {
    //             key: 'profile',
    //             label: `${info?.name} ${info?.surname}`,
    //         }],
    //     },
    //     {
    //         label: t('warehouseAddresses'),
    //         key: 'my-addresses',
    //         icon: <MapMarkerIcon />,
    //     },
    //     {
    //         label: t('parcels'),
    //         key: 'parcels',
    //         icon: <BoxIcon />,
    //     },
    //     {
    //         label: t('howToMakeOrder'),
    //         key: 'howToMakeOrder',
    //         icon: <QuestionCircleOutlined />,
    //         href: 'https://telega.pro/guide',
    //     },
    //     // {
    //     //     label: t('myPurchases'),
    //     //     key: 'purchases',
    //     //     icon: <BagIcon />,
    //     // },
    //     // {
    //     //     label: t('makeOrder'),
    //     //     key: 'make-order',
    //     //     icon: orderTypes.loading ? <Spin size="small" /> : <ListIcon />,
    //     //     children: getOrderTypesChildren(),
    //     // },
    // ];

    if (drawer) {
        return (
            <div className="mobile-menu">
                <Row>
                    <Col xs={24}>
                        <Space className="w-100" direction="vertical">
                            <Button className="mobile-menu-item" icon={<UserIcon />} onClick={() => setProfileOpen((prev) => !prev)}>
                                {t('profile')}
                                {profileOpen ? <DownOutlined /> : <RightOutlined />}
                            </Button>
                            {profileOpen && (
                                <>
                                    <Link to="/orders">
                                        <Button className="mobile-menu-sub-item" type="link">
                                            {t('orders')}
                                        </Button>
                                    </Link>
                                    <Link to="/profile">
                                        <Button className="mobile-menu-sub-item" type="link">
                                            {typeof info?.name === 'string' && info.name.length > 0 ? `${info.name} ${info.surname}` : t('profile')}
                                        </Button>
                                    </Link>
                                </>
                            )}
                        </Space>
                    </Col>
                    <Col xs={24}>
                        <Divider />
                    </Col>
                    <Col xs={24}>
                        <Link to="/my-addresses">
                            <Button className="mobile-menu-item" icon={<MapMarkerIcon />}>
                                {t('warehouseAddresses')}
                                <RightOutlined />
                            </Button>
                        </Link>
                    </Col>
                    <Col xs={24}>
                        <Divider />
                    </Col>
                    <Col xs={24}>
                        <Link to="/parcels">
                            <Button className="mobile-menu-item" icon={<BoxIcon />}>
                                {t('parcels')}
                                <RightOutlined />
                            </Button>
                        </Link>
                    </Col>
                    <Col xs={24}>
                        <Divider />
                    </Col>
                    <Col xs={24}>
                        <a href={`https://telega.pro/${links[i18n.language].guide}`} target="_blank" rel="noreferrer">
                            <Button className="mobile-menu-item" icon={<QuestionCircleFilled />}>
                                {t('howToMakeOrder')}
                                <RightOutlined />
                            </Button>
                        </a>
                    </Col>
                    <Col xs={24}>
                        <Divider />
                    </Col>
                    <Col xs={24}>
                        <a href={`https://telega.pro/${links[i18n.language].faq}`} target="_blank" rel="noreferrer">
                            <Button className="mobile-menu-item" icon={<InfoCircleFilled />}>
                                {t('faq')}
                                <RightOutlined />
                            </Button>
                        </a>
                    </Col>
                    <Col xs={24}>
                        <Divider />
                    </Col>
                    <Col xs={24}>
                        <a href="https://client.telega.pro/calculator" target="_blank" rel="noreferrer">
                            <Button className="mobile-menu-item" icon={<CalculatorFilled />}>
                                {t('calculator')}
                                <RightOutlined />
                            </Button>
                        </a>
                    </Col>
                    <Col xs={24}>
                        <Divider />
                    </Col>
                    <Col xs={24}>
                        <a href={`https://telega.pro/${links[i18n.language].contacts}`} target="_blank" rel="noreferrer">
                            <Button className="mobile-menu-item" icon={<CustomerServiceFilled />}>
                                {t('support')}
                                <RightOutlined />
                            </Button>
                        </a>
                    </Col>
                    <Col xs={24}>
                        <Divider />
                    </Col>
                    {/* <Col xs={24}>
                        <Link to="/purchases">
                            <Button className="mobile-menu-item" icon={<BagIcon />}>
                                {t('myPurchases')}
                                <RightOutlined />
                            </Button>
                        </Link>
                    </Col>
                    <Col xs={24}>
                        <Divider />
                    </Col> */}
                    {/* <Col xs={24}>
                        <Space className="w-100" direction="vertical">
                            <Button className="mobile-menu-item" icon={<ListIcon />} onClick={() => setOrderTypesOpen((prev) => !prev)}>
                                {t('makeOrder')}
                                {orderTypesOpen ? <DownOutlined /> : <RightOutlined />}
                            </Button>
                            {orderTypesOpen && orderTypes.data.length > 0 && orderTypes.data.map((ot) => (
                                <Link to={`make-order/${ot.value}`}>
                                    <Button className="mobile-menu-sub-item" type="link">
                                        {ot.label}
                                    </Button>
                                </Link>
                            ))}
                        </Space>
                    </Col> */}
                    <Col xs={24}>
                        <Space className="mobile-menu-bottom-actions">
                            <LanguagesDropDown />
                            <Link to="/profile">
                                <Button type="link" icon={<UserIcon height={32} width={32} style={{ fill: '#db8912' }} />} />
                            </Link>
                        </Space>
                    </Col>
                </Row>
            </div>
        );
    }

    return (
        <>
            <div className="menu-logo-container">
                <a href={links[i18n.language].logo} target="_blank" rel="noreferrer">
                    <img alt="Telega" className="menu-logo" src={collapsed ? LogoNoTextPNG : LogoPNG} />
                </a>
            </div>
            <Divider />
            <AMenu
                selectedKeys={[activeKey]}
                mode="inline"
            >
                <AMenu.SubMenu title={t('profile')} icon={<UserIcon />}>
                    <AMenu.Item
                        key="orders"
                        onClick={() => onItemClick('orders')}
                    >
                        {t('orders')}
                    </AMenu.Item>
                    <AMenu.Item
                        key="profile"
                        onClick={() => onItemClick('profile')}
                    >
                        {typeof info?.name === 'string' && info.name.length > 0 ? `${info.name} ${info.surname}` : t('profile')}
                    </AMenu.Item>
                </AMenu.SubMenu>
                <AMenu.Item
                    key="my-addresses"
                    onClick={() => onItemClick('my-addresses')}
                    icon={<MapMarkerIcon />}
                >
                    {t('warehouseAddresses')}
                </AMenu.Item>
                <AMenu.Item
                    key="parcels"
                    onClick={() => onItemClick('parcels')}
                    icon={<BoxIcon />}
                >
                    {t('parcels')}
                </AMenu.Item>
                <AMenu.Item
                    key="howToMakeOrder"
                    icon={<QuestionCircleFilled />}
                >
                    <a href={`https://telega.pro/${links[i18n.language].guide}`} target="_blank" rel="noreferrer">
                        {t('howToMakeOrder')}
                    </a>
                </AMenu.Item>
                <AMenu.Item
                    key="faq"
                    icon={<InfoCircleFilled />}
                >
                    <a href={`https://telega.pro/${links[i18n.language].faq}`} target="_blank" rel="noreferrer">
                        {t('faq')}
                    </a>
                </AMenu.Item>
                <AMenu.Item
                    key="calculator"
                    icon={<CalculatorFilled />}
                >
                    <a href="https://client.telega.pro/calculator" target="_blank" rel="noreferrer">
                        {t('calculator')}
                    </a>
                </AMenu.Item>
                <AMenu.Item
                    key="support"
                    icon={<CustomerServiceFilled />}
                >
                    <a href={`https://telega.pro/${links[i18n.language].contacts}`} target="_blank" rel="noreferrer">
                        {t('support')}
                    </a>
                </AMenu.Item>
            </AMenu>
        </>
    );
}

Menu.defaultProps = {
    collapsed: false,
};

export default Menu;
