import { Translations } from '../types/translation';
import { ruTerms } from './ru-terms';

export const RuLanguage: Record<Translations, string> = {
    login: 'Войти',
    logout: 'Выход',
    profile: 'Профиль',
    makeOrder: 'Оформить заказ',
    myOrders: 'Мои заказы',
    delete: 'Удалить',
    name: 'Имя',
    phone: 'Номер телефона',
    surname: 'Фамилия',
    transactions: 'Транзакции',
    uploadPhoto: 'Загрузить фото',
    userSettings: 'Настройки пользователя',
    email: 'Эл. почта',
    save: 'Сохранить',
    transactionsEmpty: 'Список транзакций пуст',
    addressSettings: 'Настройка адреса доставки',
    apartmentNumber: 'Номер квартиры/апартаментов',
    city: 'Город',
    country: 'Страна',
    district: 'Район',
    entrance: 'Подъезд',
    houseNumber: 'Дом',
    region: 'Регион',
    street: 'Улица',
    cancel: 'Отмена',
    userDeleteContent: 'После удаления, учетная запись станет недоступной',
    userDeleteTitle: 'Вы действительно хотите удалить учетную запись?',
    applicationCompleted: 'Оформление заявки завершено',
    deliveryDate: 'Дата доставки',
    direction: 'Направление',
    fromWhom: 'От кого',
    paid: 'Оплачен',
    sendTime: 'Время отправления',
    shippingCompany: 'Компания-перевозчик',
    status: 'Статус',
    toWhom: 'Кому',
    trackingNumber: 'Номер отслеживания',
    editOrder: 'Редактировать заказ',
    payOrder: 'Оплатить заказ',
    payOrderTitle: 'Оплата заказа №',
    currency: 'Валюта',
    sum: 'Сумма',
    pay: 'Оплатить',
    orderFrom: 'Откуда',
    orderPackageCost: 'Стоимость',
    orderPackageHeight: 'Высота',
    orderPackageItemsCount: 'Количество предметов',
    orderPackageLength: 'Длина',
    orderPackageCode: 'Код товара',
    orderPackageName: 'Содержимое посылки',
    orderPackageRuName: 'Содержимое посылки (Русский язык)',
    orderPackageWeight: 'Вес',
    orderPackageWidth: 'Ширина',
    orderPackageInformation: 'Информация о посылке',
    orderTo: 'Куда',
    back: 'Назад',
    next: 'Далее',
    update: 'Обновить',
    checkout: 'Оформление заказа',
    orderConfirmation: 'Подтверждение',
    orderFiller: 'Оформитель',
    orderFromTo: 'Откуда-Куда',
    orderRecipient: 'Получатель',
    orderSender: 'Отправитель',
    orderTransportation: 'Транспортировка',
    orderDeliveryToCompanyTypeTitle: 'Вариант передачи в Транспортную Компанию',
    orderFillerEmail: 'Эл. почта оформителя',
    orderPayer: 'Плательщик',
    orderMemberRecipientEmail: 'Эл. почта',
    orderMemberRecipientFullAddress: 'Полный адрес',
    orderMemberRecipientName: 'Имя',
    orderMemberRecipientPhone: 'Телефон',
    orderMemberRecipientPostalCode: 'Почтовый индекс',
    orderMemberRecipientSurname: 'Фамилия',
    orderMemberRecipientMiddlename: 'Отчество',
    orderMemberRecipientCity: 'Город',
    orderMemberRecipientStreet: 'Улица',
    orderMemberRecipientHouse: 'Номер дома',
    orderMemberRecipientBuilding: 'Номер строения',
    orderMemberRecipientApartment: 'Номер квартиры/апартаментов',
    orderMemberRecipientRegion: 'Регион',
    orderMemberRecipientRepresentative: 'Имя представителя',
    orderMemberSenderEmail: 'Эл. почта',
    orderMemberSenderFullAddress: 'Полный адрес',
    orderMemberSenderName: 'Имя',
    orderMemberSenderPhone: 'Телефон',
    orderMemberSenderPostalCode: 'Почтовый индекс',
    orderMemberSenderSurname: 'Фамилия',
    orderMemberSenderMiddlename: 'Отчество',
    orderMemberSenderCity: 'Город',
    orderMemberSenderStreet: 'Улица',
    orderMemberSenderHouse: 'Номер дома',
    orderMemberSenderBuilding: 'Номер строения',
    orderMemberSenderApartment: 'Номер квартиры/апартаментов',
    orderMemberSenderRegion: 'Регион',
    orderMemberPassport: 'Паспорт',
    orderMemberPassportDate: 'Дата выдачи',
    orderMemberPassportDivisionCode: 'Код подразделения',
    orderMemberPassportIssuingAuthority: 'Кем выдан',
    orderMemberPassportNumber: 'Номер паспорта',
    orderMemberPassportSerialNumber: 'Серия паспорта',
    orderCancel: 'Отменить',
    orderConfirm: 'Подтвердить заказ',
    userInfoUpdatedMessage: 'Информация пользователя успешно обновлена',
    userPhotoUpdatedMessage: 'Фотография пользователя успешно обновлена',
    no: 'Нет',
    yes: 'Да',
    authorization: 'Авторизация',
    authorizationByEmail: 'Вход по эл. почте',
    authorizationByPhone: 'Вход по телефону',
    authorizationType: 'Тип авторизации',
    passwordForgot: 'Забыли пароль?',
    registration: 'Регистрация',
    password: 'Пароль',
    field: 'Поле',
    isMandatory: 'является обязательным',
    enteredIncorrectly: 'введено неверно',
    confirmPassword: 'Подтверждение пароля',
    register: 'Зарегистрироваться',
    passwordsDoNotMatch: 'Введенные пароли не совпадают',
    registrationRejected: 'Регистрация отклонена',
    registrationRejectedMessage: 'Вы не приняли условия пользования сервисом',
    serviceName: 'Телега',
    termsOfService: 'Условия пользования сервисом',
    passwordRestore: 'Восстановление пароля',
    passwordRestoreMessage: 'Код для сброса пароля отправлен на почту',
    passwordRestoredMessage: 'Пароль успешно сброшен',
    restore: 'Восстановить',
    accept: 'Принять',
    welcome: 'Добро пожаловать',
    goToOrdersList: 'Перейти к списку заказов',
    orderPlacedSuccessfully: 'Заказ успешно размещен!',
    yourOrderNumber: 'Номер вашего заказа:',
    httpRequestUnexpectedError: 'Непредвиденная ошибка',
    httpRequestServeError: 'Ошибка загрузки данных',
    httpRequestUserNotAuthorized: 'Пользователь не авторизован',
    httpRequestUserNotFound: 'Запрашиваемый объект не найден',
    termsContent: ruTerms,
    orderCreatedDate: 'Дата оформления',
    emailNotConfirmed: 'Эл. почта не подтверждена',
    confirm: 'Подтвердить',
    confirmationCode: 'Код подтверждения',
    getQrCode: 'Получить QR-код',
    amountToPay: 'Сумма к оплате',
    paySucceed: 'Оплата прошла успешно',
    deliveryTime: 'Сроки доставки',
    deliveryType: 'Тип доставки',
    orderQrInfo: 'Пожалуйста, отсканируйте данный код с помощью вашего смартфона или планшета, после чего ярлык для отправки сохранится на ваше устройство. Запакуйте вашу посылку и отнесите ее в ближайшее отделение GLS в вашем городе. На месте предъявите данный ярлык, сотрудник отделения распечатает и наклеит его на вашу посылку.',
    inputOfLatinLetters: 'Ввод только буквами английского алфавита',
    amountToPayChanged: 'Стоимость заказа изменилась',
    incorrectPhoneNumber: 'Неверно введен номер телефона',
    paymentTypeSelection: 'Выберите тип платежной системы',
    maxLength: 'Максимальная длина',
    maxWeight: 'Максимальный вес',
    maxGirth: 'Максимальный обхват',
    sanctionedGoodsInfo: 'Убедительно просим Вас ознакомиться со списком санкционных товаров, запрещенных к ввозу в Российскую Федерацию',
    deleteConfirmation: 'Вы действительно хотите удалить выбранную запись?',
    confirmAction: 'Подтвердите действие',
    orderPackageItemDuty: 'Отправления стоимостью выше {amount} евро облагаются пошлиной в размере {percent}% в части превышения заявленной стоимости порога для беспошлинного ввоза в РФ',
    orderMemberFullNameInfo: 'Если ваше имя/фамилия пишутся кириллицей, пользуйтесь, пожалуйста, транслитерацией',
    orderMemberCityInfo: 'Пожалуйста, введите название города на английском языке',
    orderMemberStreetInfo: 'Пожалуйста, напишите название улицы транслитерацией',
    orderMemberRegionInfo: 'Пожалуйста, введите название региона на английском языке',
    orderPackageItemUrl: 'Ссылка на товар',
    orderPackagePickupDate: 'Дата забора посылки',
    expressShipment: 'Экспресс-доставка',
    regularShipment: 'Обычная доставка',
    parcelInsurance: 'Страховка посылки',
    myPurchases: 'Мои покупки',
    shopName: 'Название магазина',
    warehouse: 'Склад',
    goodsList: 'Список товаров',
    goods: 'Товар',
    myAddresses: 'Мои адреса',
    address: 'Адрес',
    companyName: 'Получатель посылки',
    postalCode: 'Почтовый индекс',
    workMode: 'Режим работы',
    fullName: 'Имя полностью',
    orderType: 'Тип заказа',
    perItem: 'за единицу товара',
    parcelDimensionsAttentionInformation: 'Просим вас вводить габариты и вес корректно! В случае расхождения заявленных и фактических данных, стоимость услуги будет откорректирована.',
    promoCode: 'Промокод',
    apply: 'Применить',
    discount: 'Скидка',
    paidAmount: 'Оплаченная сумма',
    goodName: 'Наименование товара',
    representativePlaceholder: 'Fedorov Fedor Fedorovich',
    acceptTermsOfService: 'Принимаю условия действующей оферты',
    itemTotalCost: 'Общая стоимость данной позиции',
    addPurchase: 'Добавить покупку',
    addGood: 'Добавить товар',
    purchaseAdding: 'Добавление покупки',
    productAdding: 'Добавление товара',
    acceptPersonalData: 'Соглашаюсь на обработку персональных данных',
    acceptTermsOfUse: 'Принимаю условия пользования сервисом',
    courierPickUpInfo: 'Курьер  заберет вашу посылку с 8.00 до 18.00',
    inn: 'ИНН',
    glsParcelShops: 'Найти пункт приема',
    totalWeight: 'Общий вес',
    warehouseAddresses: 'Адреса складов',
    parcels: 'Посылки',
    orders: 'Заказы',
    parcelType: 'Тип посылки',
    fillOrderPackages: 'Указать содержимое посылки',
    autoFill: 'Автозаполнение',
    sendingParcelToRussia: 'Отправка посылки в РФ',
    shoppingFromStores: 'Покупки из магазинов',
    china: 'Китай',
    europe: 'Европа',
    howToMakeOrder: 'Как оформить заказ?',
    faq: 'Частые вопросы',
    calculator: 'Калькулятор доставки',
    support: 'Поддержка',
    openMenu: 'Открыть меню',
    selectPurchasesError: 'Выберите вашу покупку для отправки',
    pleaseDownloadLabel: 'Пожалуйста, не забудьте распечатать лейбл',
    addFirstPurchase: 'Добавить покупку №1',
    addMorePurchase: 'Добавить ещё покупку',
    orderWithWarehouseInfo: 'При заказах с маркетплейсов убедительно просим вас указывать получателем заказа нашу компанию, адрес доставки - адрес нашего соответствующего склада.',
    regularParcel: 'Обычная доставка',
    contacts: 'Контакты',
};
