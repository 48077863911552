import {
    Typography, Modal, Space, Spin, notification, Button,
} from 'antd';
import {
    forwardRef, useImperativeHandle, useRef, useState, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
    Elements, PaymentElement, useElements, useStripe,
} from '@stripe/react-stripe-js';
import { loadStripe, PaymentIntentResult, StripeError } from '@stripe/stripe-js';
import { useStripeOrderPay } from '../../hooks/stripe/useStripeOrderPay';
import { IOrder } from '../../types/order';
import { getStripeLocale } from '../../helpers/stripe';
import { Languages } from '../../services/language';
import { config } from '../../constants/config';
import { useFetch } from '../../hooks/useFetch';

const stripe = loadStripe(config.stripeKey);

interface IOrderPayModalProps {
    onPaySucceed?: () => void;
}

export interface IOrderPayModalRef {
    show: (order: IOrder) => void;
}

interface IStripeFormRef {
    confirmPayment: () => (Promise<PaymentIntentResult> & Promise<{
        error: StripeError;
    }>) | undefined;
}

const Form = forwardRef((_: any, ref) => {
    const elements = useElements();
    const hook = useStripe();

    const confirmPayment = () => hook?.confirmPayment({
        // @ts-ignore
        elements,
        // @ts-ignore
        redirect: 'if_required',
    });

    useImperativeHandle(ref, (): IStripeFormRef => ({
        confirmPayment,
    }));

    return <PaymentElement />;
});

const StripeOrderPayModal = forwardRef(({ onPaySucceed }: IOrderPayModalProps, ref) => {
    const { i18n, t } = useTranslation();
    const orderFetch = useFetch();
    const [order, setOrder] = useState<IOrder>();
    const op = useStripeOrderPay(order);
    const form = useRef<IStripeFormRef>();
    const [payLoading, setPayLoading] = useState(false);
    const [orderId, setOrderId] = useState<number>();
    const selectedRow = useRef<IOrder>();

    const getOrderData = async (id?: number | string) => {
        if (typeof id !== 'undefined') {
            const result = await orderFetch.request<IOrder>({
                url: `/orders/${id}`,
            });

            if (result.success && result.data) {
                setOrder(result.data);
            }
        }
    };

    const hide = () => setOrderId(undefined);

    const show = (x: IOrder) => {
        selectedRow.current = x;
        setOrderId(x.id);
    };

    const onPayClick = async () => {
        setPayLoading(true);
        const result = await form.current?.confirmPayment();
        if (typeof result?.error === 'undefined') {
            if (typeof onPaySucceed === 'function') {
                onPaySucceed();
            }
            notification.success({ message: t('paySucceed') });
            hide();
        }
        if (result?.error instanceof Object) {
            notification.error({ message: result.error.message || t('httpRequestUnexpectedError') });
        }
        setPayLoading(false);
    };

    useImperativeHandle(ref, (): IOrderPayModalRef => ({
        show,
    }));

    useEffect(() => {
        if (op.paid) {
            hide();
            if (typeof onPaySucceed === 'function') {
                onPaySucceed();
            }
        }
    }, [op.paid]);

    useEffect(() => {
        if (typeof orderId === 'number') {
            getOrderData(orderId);
        } else {
            selectedRow.current = undefined;
            setOrder(undefined);
        }
    }, [orderId]);

    return (
        <Modal
            closable={false}
            className="stripe-order-payment-modal"
            title={`${t('payOrderTitle')}${orderId}`}
            open={typeof orderId === 'number'}
            footer={(
                <Space className="form-action-buttons" size="large">
                    <Button type="link" onClick={hide}>{t('cancel')}</Button>
                    <Button type="primary" onClick={onPayClick} loading={payLoading}>{t('pay')}</Button>
                </Space>
            )}
        >
            <Space className="w-100" direction="vertical">
                {typeof order?.amount_to_pay === 'number' && (
                    <Typography.Title level={4}>
                        {`${t('amountToPay')}: ${order?.amount_to_pay_label}`}
                    </Typography.Title>
                )}
                <Spin spinning={op.loading || orderFetch.loading}>
                    {typeof op.clientSecret === 'string' && (
                        <Elements
                            stripe={stripe}
                            options={{
                                clientSecret: op.clientSecret,
                                locale: getStripeLocale(i18n.language as Languages),
                            }}
                        >
                            <Form ref={form} />
                        </Elements>
                    )}
                </Spin>
            </Space>
        </Modal>
    );
});

StripeOrderPayModal.defaultProps = {
    onPaySucceed: undefined,
};

export default StripeOrderPayModal;
