import { DefaultOptionType } from 'antd/lib/select';
import { useState } from 'react';
import { Key } from 'antd/lib/table/interface';
import { useTranslation } from 'react-i18next';
import { Button, notification } from 'antd';
import { useFetch } from './useFetch';

export const contactsLinks: Record<string, string> = {
    'de-DE': 'https://telega.pro/contacts-de',
    'en-EN': 'https://telega.pro/contacts-en',
    'ru-RU': 'https://telega.pro/contacts',
};

export const useDeliveryTypes = (orderId?: number) => {
    const fetch = useFetch({ showErrorMessage: false });
    const [data, setData] = useState<DefaultOptionType[]>([]);
    const { t, i18n } = useTranslation();

    const getData = async () => {
        const result = await fetch.request({
            url: `/delivery/types/select${
                typeof orderId === 'number' ? `?orderId=${orderId}` : ''
            }`,
        });
        if (result.success) {
            setData(result.data);
        } else {
            notification.error({
                message: (
                    <span>
                        {result.message || t('httpRequestUnexpectedError')}
                        <br />
                        <Button className="height-auto" href={contactsLinks[i18n.language]} type="link" target="_blank">
                            {t('contacts')}
                        </Button>
                    </span>
                ),
            });
        }
    };

    const getDataByPackage = async (params: {
        weight: number;
        width: number;
        length: number;
        height: number;
        insurance: boolean;
    }) => {
        const result = await fetch.request({
            url: '/delivery/types/select/package',
            method: 'post',
            data: params,
        });
        if (result.success) {
            setData(result.data);
        } else {
            notification.error({
                message: (
                    <span>
                        {result.message || t('httpRequestUnexpectedError')}
                        <br />
                        <Button className="height-auto" href={contactsLinks[i18n.language]} type="link" target="_blank">
                            {t('contacts')}
                        </Button>
                    </span>
                ),
            });
        }
    };

    const getDataByPurchases = async (values: { purchases: Key[]; insurance: boolean; }) => {
        if (values.purchases.length > 0) {
            const result = await fetch.request({
                url: '/delivery/types/select/purchases',
                method: 'post',
                data: values,
            });
            if (result.success) {
                setData(result.data);
            } else {
                notification.error({ message: result.message || t('httpRequestUnexpectedError') });
            }
        } else {
            setData([]);
        }
    };

    return {
        data,
        getData,
        getDataByPackage,
        getDataByPurchases,
        loading: fetch.loading,
    };
};
